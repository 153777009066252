import React from 'react'
import { graphql } from 'gatsby'

import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

import GraphQLErrorList from '../components/graphql-error-list'

import Layout from '../containers/layout'
import SEO from '../components/seo'

export const query = graphql`
  query StudentsPageQuery {
    students: sanityStudentsPage(_id: { regex: "/(drafts.|)studentsPage/" }) {
      title
      title1
      body
      showImage
      slide1Image {
        asset {
          _id
        }
      }
      generic {
        asset {
          _id
        }
      }
    }

    people: allSanityStudent(sort: { fields: [_createdAt], order: ASC }) {
      edges {
        node {
          id
          name
          position
          email
          tel
          slug {
            current
          }
          listTitle
          listItems {
            title
            description
            icon {
              asset {
                _id
              }
            }
            link
          }
          _rawBio
        }
      }
    }
  }
`

const ParagraphClass = 'dib relative w-100 measure-wide dark-gray fw4 mb4'
const HeadingClass = 'dib relative w-100 measure f2 fw5 mv3'

const StudentsPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const students = data && data.students
  const personNodes =
    data && data.people && mapEdgesToNodes(data.people).filter(filterOutDocsWithoutSlugs)

  if (!students) {
    throw new Error(
      'Missing "Students" page data. Open the studio at http://localhost:3333 and add "Students" page data and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO title={students.title} />
      <article>
        {/* Students */}
        <section className="dib relative w-100 bg-white black lh-copy f4" id="students">
          <div className="db center mw8 ph4 pt6 pb4">
            <h1 className={HeadingClass}>{students.title1}</h1>
            <p className={ParagraphClass}>{students.body}</p>
          </div>
          {students.slide1Image && students.slide1Image.asset && students.slide1Image.asset._id ? (
            <img
              className="dib relative w-100 pb2"
              src={imageUrlFor(buildImageObj(students.slide1Image))}
              alt={students.title1}
            />
          ) : null}
        </section>
        {/* Students nodes */}
        <section className="dib relative bg-white w-100 black lh-copy f4 mb0 mb4-l">
          <div className="db center mw8 ph4 pv4">
            {personNodes.map((object, index) => (
              <div
                className={`bb b--black-10 pv4 ${
                  students.showImage ? 'row items-top' : 'dib relative w-100 measure-wide'
                }`}
                key={`person-${index}`}
              >
                {students.showImage ? (
                  <div className="col-xs-12 col-md-3">
                    {object.image && object.image.asset && object.image.asset_id ? (
                      <img
                        className="dib relative w-100 fit br3 lawyer-pic"
                        src={imageUrlFor(buildImageObj(object.image))}
                        alt={object.name}
                      />
                    ) : (
                      <img
                        className="dib relative w-100 fit br3 bg-black lawyer-pic"
                        src={imageUrlFor(buildImageObj(students.generic))}
                        alt={object.name}
                      />
                    )}
                  </div>
                ) : null}
                <div className="col-xs-12 col-md-9">
                  <div className="dib relative w-100">
                    <strong className="dib relative w-100 f4 b black mb2">{object.name}</strong>
                    {object.position ? (
                      <p className="dib relative w-100 measure-wide dark-gray fw4 f5 mt0 mb2">
                        {object.position}
                      </p>
                    ) : null}
                    {object.listTitle ? (
                      <strong className="dib relative w-100 b f5 mv2">{object.listTitle}</strong>
                    ) : null}
                    {object.listItems && object.listItems.length > 0
                      ? object.listItems.map((object, index) => (
                          <div className="dib relative w-100" key={`edu-${index}`}>
                            <strong className="dib relative w-100 measure-wide dark-gray fw4 f6">
                              {object.title}
                            </strong>
                            {object.description ? (
                              <p className="dib relative w-100 measure-wide gray fw4 f6 mv0">
                                {object.description}
                              </p>
                            ) : null}
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </article>
    </Layout>
  )
}

export default StudentsPage
